div.main {
	position:relative;
	width:100%;
	max-width:650px;
	margin:0 auto;
	margin-top:1em;
	padding:1em;
	box-sizing:border-box;
	.fab {
		position: fixed;
		bottom:1em;
		//left:1em;
		left:50%;
		transform: translate(-50%,0),
	}
	div.enter {
		margin-top:4em;
		h1 {
			text-align:center;
			font-size:300%;
		}
		div.notice {
			text-align:center;
			font-size:90%;
			color:#555555;
		}
	}
}

div.articleInput {
	width:100%;
	max-width:650px;
	margin:0 auto;
}

div.article {
	h1 {
		margin:0;
		padding:0;
		margin-top:0.25em;
	}
	h3 {
		font-size:160%;
		margin:0;
		padding:0;
	}
	div.date {
		position:relative;
		color:gray;
		font-size:80%;
		margin-bottom:4em;
		.edit {
			position:absolute;
			top:-0.1em;
			left:6em;
		}
	}
	div.body {
		margin-bottom:3em;
		line-height:2.2em;
		p.paragraph {
			font-size:120%;
			margin-bottom:2em;
			a {
				color:green;
			}
		}
		div.warning {
			border:1px solid silver;
			border-radius:0.5em;
			padding:1em;
			//padding-top:0.5em;
			margin-bottom:1em;
			//background-color:#FFEEEE;
			h4 {
				margin:0;
				padding:0;
				font-size:90%;
				color:#444444;
			}
			p {
				font-size:90%;
				margin:0;
				padding:0;
				color:#666666;
			}
		}
		div.code {
			border:1px solid #E5E5E5;
			border-radius:0.5em;
			background-color:#EEEEEE;
			padding:1em;
			margin-bottom:1em;
			line-height:1.5em;
			pre {
				margin:0;
			}
		}
		div.image {
			margin-bottom:1em;
			//line-height:1em;
			img {
				vertical-align: bottom;
			}
			div.caption {
				//background-color:#EEEEEE;
				//border:1px solid #EEEEEE;
				font-size:90%;
				color:#999999;
			}
		}
		div.image-border {
			border:1px solid silver;
			div.caption {
				padding:0.5em;
			}
		}
		div.image-stretched {
			img {
				width:100%;
			}
		}
		div.image-background {
			text-align:center;
			background-color:#EEEEEE;
			div.caption {
				color:#333333;
			}
		}
		div.raw {
			border:1px solid #E5E5E5;
			border-radius:0.5em;
			background-color:#000000;
			color:white;
			padding:1em;
			margin-bottom:1em;
			line-height:1.5em;
			pre {
				margin:0;
			}
		}
		div.quote {
			background-color:#EEEEFF;
			padding:1em 2em;
			margin-bottom:1em;
			border-left:3px solid silver;
			p.caption {
				font-size:90%;
				color:#666666;
			}
		}
		div.quote-left {
			//padding-left:2em;
			//margin-left:3em;
			text-align:left;
		}
		div.quote-center {
			text-align:center;
		}
		ul.checklist {
			list-style:none;
			margin-bottom:1.5em;
			li {
				margin-bottom:0.5em;
			}
		}
		div.delimiter {
			text-align:center;
			margin-bottom:1em;
		}
		a.linkTool {
			text-decoration:none;
			color:#333333;
			div.inner { 
				border:1px solid silver;
				padding:0em;
				position:relative;
				margin-bottom:2em;
				background-size: 35%;
				background-repeat:no-repeat;
				background-position:left center;
				div.text {
					position:relative;
					margin-left:35%;
					padding:1em 1em;
					min-height:5em;
					div.title {
						font-size:100%;
						font-weight:bold;
						margin-bottom:0.5em;
						white-space:nowrap;
						overflow:hidden;
						line-height:1.0em;
					}
					div.description {
						font-size:80%;
						line-height:1.2em;
						height:2.4em;
						overflow:hidden;
					}
					div.link {
						font-size:80%;
						line-height:1.2em;
						white-space:nowrap;
						overflow:hidden;
						color:#999999;
					}
				}
			}
		}
	}
	div.data {
		font-size:90%;
		color:#666666;
		text-align:right;
		margin-bottom:3em;
	}
}

.link {
	text-decoration:none;
	color: #333333;
}

.menuOverLimit {
	//text-align:center;
	font-size:80%;
	color:gray;
	padding:1em;
	padding-bottom:0;
}

.signature {
	text-align:center;
	background-color:#EEEEEE;
	padding-top:2em;
	padding-bottom:3em;
}

.withIcon {
	display:inline-flex;
	vertical-align:middle;
}

ul.personData {
	margin:0;
	padding:0;
	list-style:none;
	margin-bottom:2em;
	li {
		padding:0;
		margin:0;
		margin-left:1em;
	}
}


div.verBox {
	text-align:center;
	border:1px solid silver;
	padding:1em;
	h3 {
	  font-size:80%;
	  font-weight:normal;
	  color:#333333;
	  margin:0;
	}
	div {
	  font-size:180%;
	}
	div.latest {
	  color: green;
	}
	div.current {
	  color: pink;
	}
}
  
div.verGuide {
	margin-bottom:2em;
	h3 {
		font-size:100%;
		margin-bottom:0.5em;
	}
	div {
		font-size:90%;
		p {
		font-size:80%;
		color:#888888;
		}
	}
}

div.dialogFooter {
	text-align:center;
	padding-top:3em;
	margin-bottom:3em;
	div.logo {
		a {
			color:#333333;
			text-decoration:none;
		}		
		img {
			width:30px;
			display:inline;
		}
	}
}